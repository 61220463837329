import React from "react"
import SEO from "src/components/shared/seo"
import Layout, { Inner } from "src/components/shared/Layout"
import styles from "./reservation.styles.module.css"
import Title from "../components/shared/Title"
import ContactForm from "src/components/shared/ContactForm"

const ReservationPage = () => (
  <Layout bannerImage="lotus">
    <SEO title="Mes réservations — Turtle Tonic — Aquabike • Aquagym • Leçon de natation" />
    <Inner>
      <Title>Mes réservations</Title>
      <section className={styles.Reservation}>
        <span role="img" aria-label="information :">
          ℹ️
        </span>{" "}
        Le module de réservation peut mettre plus d'une minute à charger
        <iframe
          id="inlineFrameExample"
          title="Reservation"
          className={styles.IframeReservation}
          frameborder="0"
          src="https://turtletonic.simplybook.it/v2/#client/bookings/type/upcoming"
        />
      </section>
    </Inner>

    <Inner small>
      <ContactForm />
    </Inner>
  </Layout>
)

export default ReservationPage
